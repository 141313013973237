import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from './user-avatar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UserAvatarDirective } from './user-avatar.directive';
import { UserAvatarSizeDirective } from './user-avatar-size.directive';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { CustomPipesModule } from '@app/_pipes/custom-pipes.module';

@NgModule({
  declarations: [UserAvatarComponent, UserAvatarDirective, UserAvatarSizeDirective],
  imports: [CommonModule, NgxSkeletonLoaderModule, CustomPipesModule],
  exports: [UserAvatarComponent, UserAvatarSizeDirective],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserAvatarModule {
  static forRoot(): ModuleWithProviders<UserAvatarModule> {
    return {
      ngModule: UserAvatarModule,
      providers: [],
    };
  }
}
